import $ from 'jquery';

export default function () {
	if($('.c-fold__promo').length >= 1) {

		boxHeight();

		$(window).resize(function(){
			boxHeight();
		});
	}
}

function boxHeight() {

	var box = $('.c-fold').height() - $('.c-fold__promo').height() - 70;

	$('.c-fold__commercial').css('height', box);
	$('.c-fold__products').css('height', box);
}

