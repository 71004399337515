import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller'
import fold from './modules/fold';
import scrollTop from './modules/scrollTop';
import iframeScroll from './modules/iframeScroll';
import maxCharacters from './modules/maxCharacters';

mobileNav();
loader();
scroller();
fold();
scrollTop();
iframeScroll();
maxCharacters();