import $ from 'jquery';

export default function () {
   
     $('.c-services__fournisseurs ul li p').each(function(){
     		
     		var paragraph = $(this).text();

     		$(this).text(paragraph.substring(0,120) + '...');

     });

}