import $ from 'jquery';

export default function () {
	$('.c-contact__360').click(function() {
		console.info('test');

		$(this).find('iframe').addClass('clicked');
	});

	$('.c-contact__360').mouseleave(function() {
		$(this).find('iframe').removeClass('clicked');
	});
}